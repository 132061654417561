import { useContext, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Link, Typography } from "@mui/material";

import { AppContext } from "src/context/AppContext";
import FormProvider from "src/components/hook-form";
import { loginUrl, userDetailsEndpoint } from "src/urls";
import { errorToast, loadingToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import PasswordField from "src/newComponents/form/PasswordField";
import EmailField from "src/newComponents/form/EmailField";

const FAILED_TO_AUTHENTICATE =
  "Failed to authenticate, please follow all the steps in the support guide on the login page and try again.";

export default function AuthLoginForm() {
  const isLightMode = useIsLightMode();

  const { updateUserDetails, isAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();

  const search = window.location.href.split("?")[1];
  const queryParameters = new URLSearchParams(search);
  const nextPage = queryParameters.get("next");

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required.").email("Invalid email address."),
    password: Yup.string().required("Password is required.").min(8, "Your password must be at least 8 characters."),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAuthenticated) {
      loadingToast("You are already signed in. Redirecting to home page...", isLightMode);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [isAuthenticated]);

  const authenticateUsingStoredSessionCookie = () => {
    axios
      .get(userDetailsEndpoint)
      .then((response) => {
        const id = response.data.id;
        const email = response.data.email;
        const name = response.data.name;
        const phone = response.data.phone;
        const isSuperuser = response.data.is_superuser;
        updateUserDetails(id, email, name, phone, isSuperuser, false);
      })
      .catch((error) => {
        errorToast(FAILED_TO_AUTHENTICATE, isLightMode);
        window.scrollTo({ top: 0, behavior: "smooth" });
        console.log(error);
      });
  };

  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    axios
      .post(loginUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.key !== undefined) {
          axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");
          reset();
          if (nextPage) {
            navigate(`/${nextPage}`);
          } else {
            navigate("/");
          }

          authenticateUsingStoredSessionCookie();
        }
      })
      .catch((response) => {
        const error = response.response.data;
        const nonFieldErrors = error.non_field_errors;
        let alertSent = false;
        if (nonFieldErrors) {
          if (nonFieldErrors[0] === "Unable to log in with provided credentials.") {
            errorToast("Incorrect email address or password.", isLightMode);
            window.scrollTo({ top: 0, behavior: "smooth" });
            alertSent = true;
          }
        }
        if (!alertSent) {
          errorToast(FAILED_TO_AUTHENTICATE, isLightMode);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        console.log(error);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <EmailField hasFocus={true} tabIndex={1} />

        <PasswordField name="password" label="Password" hasFocus={false} tabIndex1={2} tabIndex2={4} />
        <div style={{ alignSelf: "flex-start" }}>
          <Link
            component={RouterLink}
            to={"/forgot-password/"}
            variant="body2"
            underline="always"
            color="text.secondary"
          >
            Forgot password?
          </Link>
          <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
            Having trouble? Refer to our{" "}
            <Link
              component={RouterLink}
              to={"https://drive.google.com/file/d/108l2B8v6RGOTCRlUC2YXQc4vSpKOObeb/view?usp=sharing"}
              underline="always"
              target="_blank"
              color="text.secondary"
            >
              Support Guide
            </Link>{" "}
            for more information.
          </Typography>
        </div>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          tabIndex={3}
          disabled={isAuthenticated}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
